import React, {FC, useCallback, useState} from 'react';
import classNames from 'classnames';
import {Button, PlusIcon, RightNavIcon} from 'components';
import {FormTextArea, FormTextInput} from 'components/Basic/Form/V2';
import {
  regenerateExerciseQuestion,
  saveExerciseDraft,
} from 'features/MagicExercise/magicExerciseActions';
import {selectMagicGeneratedExercise} from 'features/MagicExercise/magicExerciseSelectors';
import {
  ExerciseQuestionsValidationProps,
  ExerciseStepsProps,
  GeneratedExerciseData,
} from 'features/MagicExercise/utils/constants';
import {useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import RegenerateButton from '../../Components/RegenerateButton';
import {exerciseQuestionsValidationSchema} from '../../validations';

const EditExerciseQuestions: FC<ExerciseStepsProps> = ({onSubmit}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  // Destructure values from Redux state
  const {title, description, prompts, questions, patientId} = useSelector(
    selectMagicGeneratedExercise,
  ) as GeneratedExerciseData;
  const exerciseList = questions?.map(x => ({question: x})) || [];

  // Initialize form and field array with react-hook-form
  const {control, setValue, watch, handleSubmit} =
    useForm<ExerciseQuestionsValidationProps>({
      resolver: yupResolver(exerciseQuestionsValidationSchema),
      defaultValues: {
        title: title || '',
        description: description || '',
        questions: exerciseList,
      },
    });

  const {fields, append, remove} = useFieldArray({
    name: 'questions',
    control,
  });

  const [isRegeneratingQuestion, setIsRegeneratingQuestion] = useState<{
    [key: number]: boolean;
  }>({});

  const values = watch();
  const desCharLength = values?.description?.length ?? 0;
  const emptyFields = Object.values(values).some(value =>
    Array.isArray(value) ? value.length === 0 : value.trim() === '',
  );

  const handleRegenerateClick = useCallback(
    (index: number) => {
      setIsRegeneratingQuestion(prevState => ({...prevState, [index]: true}));

      dispatch(
        regenerateExerciseQuestion({
          title,
          description,
          prompts,
          patientId,
          onSuccess: (result?: string) => {
            setValue(`questions.${index}.question`, result ?? '');
            setIsRegeneratingQuestion(prevState => ({
              ...prevState,
              [index]: false,
            }));
          },
          onError: () => {
            setIsRegeneratingQuestion(prevState => ({
              ...prevState,
              [index]: false,
            }));
          },
        }),
      );
    },
    [dispatch, title, description, prompts, patientId, setValue],
  );

  const onSubmitPreview = useCallback(
    (data: ExerciseQuestionsValidationProps) => {
      const {title, description, questions: formQuestions} = data;
      dispatch(
        saveExerciseDraft({
          title,
          description,
          questions: formQuestions.map((x: {question: string}) => x.question),
          prompts,
          patientId,
        }),
      );
      setTimeout(onSubmit, 60); // Call parent onSubmit after a slight delay
    },
    [dispatch, onSubmit, prompts, patientId],
  );

  const handleAddExercise = useCallback(() => {
    append({question: ''});
    handleRegenerateClick(fields.length);
  }, [append, fields, handleRegenerateClick]);

  return (
    <div className="flex flex-col justify-center relative">
      <form>
        <div className="w-full md:w-8/12 md:max-w-xl h-auto flex flex-col gap-6 px-6 py-8 md:p-12 mx-auto mb-10 bg-white border border-gray-200 rounded-2xl shadow-2xl">
          {/* Title and Description Fields */}
          <div className="w-full flex flex-col gap-3">
            <FormTextInput
              id="title"
              name="title"
              control={control}
              label={t('magicExercise.exerciseTitle', 'Exercise Title')}
              classes="pt-0"
              labelClasses="font-inter text-xs leading-4 font-medium"
              inputClasses="font-inter text-sm font-medium leading-4 w-full h-12 px-5 rounded-xl"
              errorClasses="!text-left"
              isRequired={true}
            />
            <FormTextArea
              id="description"
              name="description"
              control={control}
              label={
                <div className="flex flex-row justify-between mb-2">
                  <p className="font-inter text-xs leading-4 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                    {t('magicExercise.addDescription', 'Add description')}
                  </p>
                  <span className="font-inter text-xs leading-4 font-medium">
                    {desCharLength}/120
                  </span>
                </div>
              }
              containerClasses="pt-0"
              classes="font-inter text-sm font-medium leading-4 p-5 rounded-xl"
              errorClasses="!text-left"
              rows={3}
              maxChars={120}
              isRequired={true}
            />
          </div>
          {/* Dynamic Exercise Fields */}
          <div className="w-full flex flex-col gap-5">
            {fields.map((field, index) => (
              <FormTextArea
                key={`${index}-${field.id}`}
                id={`questions.${index}.question`}
                name={`questions.${index}.question`}
                control={control}
                label={
                  <div className="flex justify-between mb-2">
                    <p className="font-inter text-xs leading-4 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                      {index + 1}. {t('magicExercise.exercise', 'Exercise')}
                    </p>
                    <div className="flex gap-2">
                      <RegenerateButton
                        isRegenerating={isRegeneratingQuestion[index] || false}
                        onRegenerateClick={() => handleRegenerateClick(index)}
                      />
                      <button
                        type="button"
                        className="font-inter text-xs leading-3 font-medium text-darkGray underline bg-transparent bottom-0 outline-none"
                        onClick={() => remove(index)}
                        disabled={isRegeneratingQuestion[index]}
                      >
                        {t('magicExercise.delete', 'Delete')}
                      </button>
                    </div>
                  </div>
                }
                containerClasses="pt-0"
                labelClasses="font-inter text-xs leading-4 font-medium"
                classes="font-inter text-xs font-medium leading-4 px-5 rounded-xl"
                errorClasses="!text-left"
                rows={2}
              />
            ))}
          </div>
          <Button
            type="button"
            btnType="custom"
            fontSize="sm"
            fontWeight="medium"
            textColor="black"
            borderColor="gray-400"
            borderRadius="xl"
            className="font-inter leading-tight h-12 px-6 w-auto border hover:bg-gray-900 hover:text-white hover:border-black flex flex-row items-center justify-center gap-1 self-start"
            onClick={handleAddExercise}
          >
            <PlusIcon width={10} height={9} strokeColor="black" />
            &nbsp;{t('magicExercise.addNewExercise', 'Add new exercise')}
          </Button>
        </div>

        <Button
          type="submit"
          btnType="custom"
          fontSize="sm"
          fontWeight="medium"
          textColor="black"
          bgColor="white"
          borderColor="gray-400"
          borderRadius="full"
          className={classNames(
            'z-999 fixed right-10 bottom-10 w-auto h-12 flex items-center justify-center px-6 font-inter !leading-3 border',
            {
              'hover:bg-black hover:text-white hover:border-black':
                !emptyFields,
              'bg-black': emptyFields,
            },
          )}
          disabled={emptyFields}
          onClick={handleSubmit(onSubmitPreview)}
        >
          {t('magicExercise.preview', 'Preview')}&nbsp;
          <RightNavIcon
            width={18}
            height={18}
            strokeColor="black"
            strokeWidth={2}
          />
        </Button>
      </form>
    </div>
  );
};

export default EditExerciseQuestions;
